import React from "react"
import { chunk } from "lodash"
import useAspectRatio from "../hooks/useAspectRatio"
import GatsbyImage from "../components/gatsbyImage"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { v4 as uuid } from "uuid"
import ButtonBase from "@material-ui/core/ButtonBase"
import Modal from "@material-ui/core/Modal"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import useMuiState from "../hooks/useMuiState"
import IconButton from "@material-ui/core/IconButton"
import CancelRoundedIcon from "@material-ui/icons/CancelRounded"
import Color from "color"

const useStyles = makeStyles(({ palette, spacing, shadows }) => ({
  root: {
    display: "flex",
    width: "100%",
    maxWidth: 1250,
    margin: "0 auto",
  },
  button: {
    transition: "all .8s ease-out",
    "&:hover, &:focus": {
      transform: "scale(1.03)",
      zIndex: 1,
      backgroundColor: palette.primary.main,
      cursor: "pointer",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "100vw",
    height: "100vh",
    position: "absolute",
    backgroundColor: "transparent",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  close: {
    position: "absolute",
    top: spacing(1),
    right: spacing(1),
    zIndex: 5,
  },
  modalImage: {
    width: "100%",
    maxHeight: "100vmin",
    maxWidth: "100vmin",
  },
  backdrop: {
    backgroundColor: Color(palette.secondary.main)
      .darken(0.9)
      .alpha(0.75)
      .hsl()
      .string(),
  },
}))

const Gallery = ({ items }) => {
  const getAspectRatio = useAspectRatio()
  const classes = useStyles()
  const theme = useTheme()
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"))
  const mdDown = useMediaQuery(theme.breakpoints.down("md"))

  const numberPerRow = xsDown ? 1 : mdDown ? 2 : 3
  const rows = chunk(items, numberPerRow)

  return rows.map(row => {
    const randomKey = uuid()

    const rowAspectRatioSum = row.reduce(
      (acc, item) => acc + getAspectRatio(item),
      0
    )

    return (
      <div key={randomKey} className={classes.root}>
        {row.map(img => (
          <ImageButton
            key={uuid()}
            img={img}
            aspectRatioSum={rowAspectRatioSum}
          />
        ))}
      </div>
    )
  })
}

const ImageButton = ({ img, aspectRatioSum }) => {
  const getAspectRatio = useAspectRatio()
  const classes = useStyles()
  const theme = useTheme()
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"))
  const smDown = useMediaQuery(theme.breakpoints.down("sm"))
  const mdDown = useMediaQuery(theme.breakpoints.down("md"))
  const [handleOpen, handleClose, open] = useMuiState(false)

  return (
    <>
      <ButtonBase
        key={img}
        focusRipple
        style={{
          width: `${(getAspectRatio(img) / aspectRatioSum) * 100}%`,
        }}
        className={classes.button}
        onClick={handleOpen}
      >
        <GatsbyImage
          imgPath={img}
          style={{
            width: `100%`,
          }}
          imgStyle={{
            padding: xsDown ? 4 : smDown ? 8 : mdDown ? 12 : 16,
          }}
        />
      </ButtonBase>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          className: classes.backdrop,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <IconButton onClick={handleClose} className={classes.close}>
              <CancelRoundedIcon color="primary" />
            </IconButton>
            <GatsbyImage
              imgPath={img}
              className={classes.modalImage}
              imgStyle={{ objectFit: "contain" }}
            />
          </div>
        </Fade>
      </Modal>
    </>
  )
}

export default Gallery
