import useAllAssets from "./useAllAssets"

const useAspectRatio = () => {
  const images = useAllAssets()

  return imgKey => {
    const relPath = imgKey.split(/[/ ]+/).pop()
    const [img] = images.filter(img => img.node.relativePath === relPath)

    return img.node.childImageSharp.fluid.aspectRatio
  }
}

export default useAspectRatio
