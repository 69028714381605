import React from "react"
import Layout from "../components/layout"
import Typography from "@material-ui/core/Typography"
import SEO from "../components/seo"
import { makeStyles } from "@material-ui/core/styles"
import Gallery from "../components/gallery"

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    width: "100%",
    padding: spacing(8),
    [breakpoints.down("xs")]: {
      padding: spacing(8, 1),
    },
    [breakpoints.down("sm")]: {
      padding: spacing(8, 2),
    },
    [breakpoints.down("md")]: {
      padding: spacing(8, 4),
    },
  },
  header: {
    paddingBottom: spacing(4),
  },
}))

const CategoryPage = ({ pageContext: { category, galleryItems } }) => {
  const classes = useStyles()

  return (
    <Layout>
      <SEO title={category} />
      <main className={classes.root}>
        <Typography
          variant="h3"
          component="h1"
          align="center"
          color="secondary"
          className={classes.header}
        >
          {category}
        </Typography>
        <Gallery items={galleryItems} />
      </main>
    </Layout>
  )
}

export default CategoryPage
